// import $ from "jquery";

// import 'slick-carousel';
// import '@fancyapps/fancybox';
// import popper from "popper.js";
// import bootstrap from "bootstrap";

var $ = require("jquery");
window.jQuery = $;
require("slick-carousel");
require("@fancyapps/fancybox");

$(document).ready(function () {
  const ajax_url_from_wp = php_ajax_url;


  $(".hamburger").click(function () {
    $(".header-menu").toggleClass("header-menu-active");
    $('.hamburger').toggleClass("hamburger-active");

  });

  jQuery(".fancybox").fancybox().attr('data-fancybox', 'gallery');
  jQuery(".wp-block-gallery .blocks-gallery-item a").fancybox().attr('data-fancybox', 'gallery');

  $(document).mouseup(function (e) {
    var $target = $(e.target);

    if ($target.closest(".header-menu").length == 0 && $target.closest(".hamburger").length == 0) {
      $(".header-menu").removeClass("header-menu-active");
      $(".hamburger").removeClass("hamburger-active");
    }
  });

  $(".search-btn").click(function (e) {
    e.preventDefault();
    $(".our-search").toggleClass("our-search-active");
  });
  $(document).mouseup(function (e) {
    var $target = $(e.target);

    if ($target.closest(".our-search").length == 0 && $target.closest(".search-btn").length == 0) {

      $(".our-search").removeClass("our-search-active");
    }
  });

  $('.close-btn').on('click', function (e) {
    e.preventDefault();
    $('.our-search').css({
      'visibility': 'hidden',
      'opacity': 0,
      'transition': '0.3s all'
    });

  });

  $('#searchform-mobile').appendTo('.header-menu-container');

  $('#flags_language_selector').appendTo('.header-menu-container');


  //header height
   let headerHeight = $('.header').outerHeight(true);
   let styles = {'padding-top':headerHeight/2 + 50}
    $(".wrapper").css(styles);


 

  $(".slider").slick({
    autoplay: true,
    autoplaySpeed: 3000,
    vertical: true,
    verticalSwiping: false,
    dots: true,
    arrows: false,
    
    

    customPaging: function (slider, i) {
      return '<a href="#"><img src="' + ajax_url_from_wp.path_image + 'dot-empty.png" /><img src="' + ajax_url_from_wp.path_image + 'dot-active.png" /></a>';
    },
    responsive: [

      {
      breakpoint: 821,
      settings: {
        
        vertical: false,
        verticalSwiping: false,
        dots: true

      }
    }, ]
  });




  // $(window).on('orientationchange', function() {
  //   $('.slider').slick('resize');
  // });
 $('.feedback').find('br,p').remove();

});

